import { N20MainMenu, N50Breadcrumb, N60Footer } from '~/features/Navigation';
import { MetaFields } from '../../pages/shared/MetaFields';
import { StyledMain } from '../styled';
import DynamicScripts from '../utils/DynamicScripts';
import useDynamicHooks from '../utils/useDynamicHooks';
import { Schema, SchemaTypes } from '~/shared/schema';
import { useFrame, usePage } from '~/services';
import { UmbracoTypes } from '~/lib/data-contract';

export default function Standard({ children }: { children?: React.ReactNode }) {
    useDynamicHooks();
    const { data: frame } = useFrame();
    const { data: pageType } = usePage((page) => page.type);

    const { src: logo } = frame?.footer?.logoImage || {};

    return (
        <>
            <Schema
                type={SchemaTypes.Organization}
                props={{
                    logo,
                }}
            />
            <MetaFields />
            <DynamicScripts hideUserLink={pageType === UmbracoTypes.PageTypes.IP90BasketPage} />
            <N20MainMenu />
            <StyledMain>
                <N50Breadcrumb />
                {children}
            </StyledMain>
            <N60Footer />
        </>
    );
}
