import Head from 'next/head';
import { UmbracoTypes } from '~/lib/data-contract';
import { usePage } from '~/services';
import { Favicon } from '~/templates/pages/shared/Favicon';

export const MetaFields = () => {
    const { data: page } = usePage<UmbracoTypes.IPages>();
    const { title, description, canonical, hideFromRobots } = page?.meta || {};

    const pageHasYoutubeVideo = (page as UmbracoTypes.IPage)?.pageElements?.some(
        (element) =>
            (element as { video?: UmbracoTypes.IVideoMedia })?.video?.src
                ?.toLowerCase()
                ?.includes('youtube.com'),
    );

    return page?.meta ? (
        <Head>
            {title ? <title key="title">{title}</title> : null}

            {description ? (
                <meta name="description" key="description" content={description} />
            ) : null}

            {hideFromRobots ? <meta name="robots" key="robots" content="noindex" /> : null}

            {canonical ? <link rel="canonical" key="canonical" href={canonical.url} /> : null}

            {Favicon}
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <link rel="preconnect" href="https://www.googletagmanager.com" />
            {pageHasYoutubeVideo ? <link rel="preconnect" href="https://www.youtube.com" /> : null}
        </Head>
    ) : null;
};
